import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeadingCard from "../components/utils/HeadingCard"
import Div8095 from "../components/utils/Div8095"
import ContainerDiv from "../components/utils/ContainerDiv"
import styled from "styled-components"
import stories from "../json/stories.json"
import StoriesFlexBox from "../components/writerpage/StoriesFlexBox"
import ContactCard from "../components/utils/ContactCard"
const StyledGrid = styled.div`
  margin: 1em 0;
  display: grid;
  grid-template-columns: 20em 1fr;
  grid-template-rows: auto auto;
  grid-gap: 1em;
  background-color: white;
  padding: 1em;
  @media (max-width: 750px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
  }
`
const StyledPic = styled.img`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  @media (max-width: 750px) {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }
`
const StyledDesc = styled.div`
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  @media (max-width: 750px) {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
  }
`
const StoriesBy = styled.div`
  grid-column: 2 / span 1;
  @media (max-width: 750px) {
    grid-column: 1 / span 1;
    grid-row: 4 / span 1;
  }
`
const StyledH3 = styled.h3`
  border-bottom: double;
  margin: 1em 0 0 0;
  @media (max-width: 750px) {
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
  }
`
const Writer = ({ pageContext }) => {
  //stories written by this writer
  const res = stories.filter(e => e.writerID === pageContext.id)
  return (
    <Layout>
      <SEO title={pageContext.fullname} />
      <ContainerDiv bg={pageContext.color}>
        <Div8095>
          <HeadingCard w="10em">{pageContext.fullname}</HeadingCard>
          <StyledGrid>
            <StyledPic
              src={require(`../images/writers/${pageContext.image}`)}
              alt={pageContext.fullname}
            />
            <ContactCard
              contactArray={Object.values(pageContext.contactInfo)}
              name={pageContext.fullname}
            />
            <StyledDesc
              dangerouslySetInnerHTML={{ __html: pageContext.desc }}
            />
            <StoriesBy>
              {res.length > 0 ? (
                <>
                  {" "}
                  <StyledH3 className="rancho">
                    Stories by {pageContext.fullname}
                  </StyledH3>
                  <StoriesFlexBox arr={res} />
                </>
              ) : (
                ""
              )}
            </StoriesBy>
          </StyledGrid>
        </Div8095>
      </ContainerDiv>
    </Layout>
  )
}

export default Writer
