import React from "react"
import styled from "styled-components"
import Card from "./Card"
const StyledFlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0;
  paddin: 0;
`
const StoriesFlexBox = ({ arr }) => {
  const storyList = arr.map((e, i) => {
    return <Card key={i} cardpic={e.cardpic} title={e.title} slug={e.slug} />
  })
  return <StyledFlexBox>{storyList}</StyledFlexBox>
}
export default StoriesFlexBox
