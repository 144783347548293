import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledCard = styled.div`
  background-color: white;
  margin: 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  & > * {
    margin: 0;
    padding: 0;
  }
`
const StyledPic = styled.img`
  width: 4em;
`
const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
const StyledSpan = styled.span`
  margin: 1em 1em 0 1em;
  border-bottom: solid 1px transparent;
  transition: border-color 1s linear;
  &:hover {
    border-color: #2a3546;
  }
`
const Card = ({ title, slug, cardpic }) => {
  return (
    <StyledLink to={"/stories/" + slug}>
      <StyledCard>
        <StyledPic
          src={require(`../../images/stories/${cardpic}`)}
          alt={title}
        />
        <StyledSpan>{title}</StyledSpan>
      </StyledCard>
    </StyledLink>
  )
}

export default Card
